<template>
  <div>
    <div :class="{'dropzone-container': true , 'dropzone-is-square': isSquare}">
    <div
      t-data="image-container"
      :class="{
        'dropzone-image-upload-box-error': isError,
        'dropzone-image-upload-box': !isError,
        'text-center d-flex flex-column align-center align-self-center align-content-center justify-center dropzone-image-container': true
      }"
    >
      <span
        t-data="image-remove-button"
        v-if="thumbnail && !disabled"
        class="ma-2 image-remove-button"
        color="whiteColor"
        @click="remove"
      >
        <v-icon light>
          mdi-close
        </v-icon>
      </span>
      <v-img
        v-show="thumbnail"
        :src="thumbnail"
        aspect-ratio="4"
        :cover="isSquare"
        :contain="!isSquare"
        class="preview-uploaded-image"
      ></v-img>
      <input
        v-if="!thumbnail"
        type="file"
        :accept="acceptType"
        class="input-file"
        :disabled="disabled"
        @change="update"
        @click:clear="remove"
        required
      />
      <div
        v-if="!thumbnail"
        class="text-center d-flex flex-column align-center align-self-center align-content-center justify-center"
      >
        <v-img
          src="/images/image-logo.png"
          aspect-ratio="1"
          max-height="48"
          max-width="48"
          contain
          class="preview-uploaded-image mb-2"
        ></v-img>
        <p class="image-upload-box-title" t-data="image-container-title">
          <template v-if="title !== null">{{ title }}</template>
          <template v-else
            >Drop your image here or
            <span class="input-file-text">browse</span></template
          >
        </p>
        <p
          :class="['image-upload-box-info-title-bold mb-0', subtitleFontClass]"
          t-data="image-container-subtitle"
        >
          <template v-if="subtitle !== null">{{ subtitle }}</template>
          <template v-else>
            Recommended image size 320x240px.
          </template>
        </p>
        <p
          :class="['image-upload-box-info-title', contentFontClass]"
          t-data="image-container-content"
        >
          <template v-if="content !== null">{{ content }}</template>
          <template v-else>
            (Support .jpg and .png files. Up to 1 mb. max.)
          </template>
        </p>
      </div>
    </div>
  </div>
    <div class="dropzone-error-message-container">
      <div
        t-data="error-message"
        class="flex-nowrap"
        v-if="isError"
      >
        <p
          class="edit-error-message sub-body-regular mb-0"
        >
          {{ errorMsg }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageDropzone',
  props: {
    acceptType: {
      type: String,
      required: false,
      default: 'image/png, image/jpeg'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    subtitleFontClass: {
      type: String,
      required: false,
      default: 'sub-body-bold'
    },
    content: {
      type: String,
      required: false,
      default: null
    },
    contentFontClass: {
      type: String,
      required: false,
      default: 'sub-body-regular'
    },
    thumbnail: {type: String},
    isError: {type: Boolean},
    errorMsg: {type: String},
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    },
    update(e) {
      const target = e.target
      const file = target.files[0]
      this.$emit('update', file)
    }
  }
}
</script>

<style scoped>
.dropzone-container {
  position: relative;
  width: 100%;
  padding-top: 62%;
  min-height: 200px;
}

.dropzone-is-square {
  aspect-ratio: 1;
  max-width: 300px;
  padding-top: unset;
  min-height: unset;
}

.dropzone-image-container {
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.dropzone-image-upload-box {
  border-width: 1px;
  border-radius: 4px;
  border-style: dashed;
  border-color: var(--border-color);
}

.dropzone-image-upload-box-error {
  border-color: var(--error-text-color);
  border-width: 1px;
  border-radius: 4px;
  border-style: dashed;
}

.dropzone-error-message-container {
  position: relative;
}
</style>
